<template>
    <div class="card card--px card--pt card--pb bg-company-3 bg-icons--familie color-contrast">
        <h4>Of stuur ons een bericht</h4>
        <form v-if="!sent" @submit.prevent="onSubmit">
            <fieldset class="mt-m">
                <label class="color-white">Uw naam:</label>
                <input type="text" v-model="form.name" required autocomplete="name" />
            </fieldset>

            <fieldset class="mt-m">
                <label class="color-white">Uw e-mailadres:</label>
                <input type="email" v-model="form.email" required autocomplete="email" />
            </fieldset>

            <fieldset class="mt-m">
                <label class="color-white">Uw telefoonnummer:</label>
                <input type="tel" v-model="form.phone" autocomplete="tel" />
            </fieldset>

            <fieldset>
                <label class="color-white">Uw bericht:</label>
                <textarea id="message" v-model="form.message" required />
            </fieldset>

            <button type="submit" class="bg-company-2" :loading="loading || undefined">Verzenden</button>
        </form>
        <span v-else class="bg-success-theme px-m py-r color-primary flex align-start mt-s mb-r">Succesvol verzonden</span>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import useTexts from '../composables/texts';
import { sendEmail } from '@/services/emails';

export default defineComponent({
    name: 'Contactform',
    setup() {
        const form = reactive({
            message: '',
            name: '',
            email: '',
            phone: '',
        });
        const sent = ref(false);
        const loading = ref(false);
        const { settings } = useTexts();

        function onSubmit() {
            const to: string[] = [settings.value.company_email];
            if (to) {
                loading.value = true;
                sendEmail({
                    to,
                    subject: 'Contactformulier ingevuld',
                    title: 'Het contactformulier op de website werd ingevuld.',
                    text: `
                        <strong>Naam:</strong> ${form.name}<br />
                        <strong>Emailadres:</strong> ${form.email}<br />
                        <strong>Telefoonnummer:</strong> ${form.phone}<br />
                        <strong>Bericht:</strong><br />
                        ${form.message}
                    `,
                }).then(() => {
                    sent.value = true;
                }).finally(() => {
                    loading.value = false;
                });
            }
        }

        return {
            form,
            sent,
            loading,
            onSubmit,
        };
    },
});
</script>

<style>
</style>
