import http from '@/services/http';

export function sendEmail({
    to, subject, title, text, link, linktext,
}:
{
    to: string|string[],
    subject: string,
    title: string,
    text: string,
    link?: string,
    linktext?: string,
}): Promise<any> {
    const data = {
        subject,
        to,
        template: {
            path: 'message.mjml',
            data: {
                data: {
                    title,
                    text,
                    text_url: link,
                    text_button: linktext,
                },
            },
        },
    };

    return http.post('email', data);
}

export default sendEmail;
