<template>
    <div class="contactpage">
        <div class="hero bg-company-2 color-contrast is-visible">
            <div class="c w-xl flex flex-wrap justify-between py-xxl">
                <div class="hero__text w-12 m-up:w-7 mb-xl m-down:py-xxl m-down:my-xxl s-down:text-center">
                    <h2>{{ texts.contact_title }}</h2>
                    <div v-if="texts.contact_notices" class="bg-info-theme rounded px-l py-r richtext mt-s mb-l w-10 s-down:mx-auto" v-html="texts.contact_notices" />
                    <div class="color-secondary hero__description w-11 l-up:w-10" v-html="texts.contact_text" />
                    <div class="color-secondary w-11 l-up:w-10" v-html="texts.contact_details" />
                </div>

                <div class="hero__side w-10 s:w-8 mx-auto m:w-5 l-up:w-4">
                    <div class="footer__cards flex flex-col w-12" :class="{ 'is-visible': isVisible }">
                        <ContactCards mode="sidebar" />
                    </div>
                </div>
            </div>
        </div>
        <div class="c w-xl flex flex-wrap justify-between contact__form" :class="{ 'is-visible': isVisible }">
            <div class="hero__text w-12 m-up:w-7 mb-xl m-down:py-xxl m-down:my-xxl s-down:text-center"></div>
            <div class="hero__side w-10 s:w-8 mx-auto m:w-5 l-up:w-4">
                <ContactForm />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import useTexts from '../composables/texts';
import ContactCards from '../components/ContactCards.vue';
import ContactForm from '../components/ContactForm.vue';

export default defineComponent({
    name: 'Contact',
    components: {
        ContactCards,
        ContactForm,
    },
    setup() {
        const { texts } = useTexts();
        const isVisible = ref(false);

        setTimeout(() => {
            isVisible.value = true;
        }, 500);

        return {
            texts,
            isVisible,
        };
    },
});
</script>

<style scoped>
.hero {
    padding: 3vh 0;
}
.contact__form {
    margin-top: calc(-3vh - calc(2 * var(--size-xxl)));
    margin-bottom: 3vh;
}
.contact__form .hero__text {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
@media (min-width: 62em) {
    .hero__description {
        font-size: var(--size-4);
    }
}
</style>
