
import { defineComponent, reactive, ref } from 'vue';
import useTexts from '../composables/texts';
import { sendEmail } from '@/services/emails';

export default defineComponent({
    name: 'Contactform',
    setup() {
        const form = reactive({
            message: '',
            name: '',
            email: '',
            phone: '',
        });
        const sent = ref(false);
        const loading = ref(false);
        const { settings } = useTexts();

        function onSubmit() {
            const to: string[] = [settings.value.company_email];
            if (to) {
                loading.value = true;
                sendEmail({
                    to,
                    subject: 'Contactformulier ingevuld',
                    title: 'Het contactformulier op de website werd ingevuld.',
                    text: `
                        <strong>Naam:</strong> ${form.name}<br />
                        <strong>Emailadres:</strong> ${form.email}<br />
                        <strong>Telefoonnummer:</strong> ${form.phone}<br />
                        <strong>Bericht:</strong><br />
                        ${form.message}
                    `,
                }).then(() => {
                    sent.value = true;
                }).finally(() => {
                    loading.value = false;
                });
            }
        }

        return {
            form,
            sent,
            loading,
            onSubmit,
        };
    },
});
