
import { defineComponent, ref } from 'vue';
import useTexts from '../composables/texts';
import ContactCards from '../components/ContactCards.vue';
import ContactForm from '../components/ContactForm.vue';

export default defineComponent({
    name: 'Contact',
    components: {
        ContactCards,
        ContactForm,
    },
    setup() {
        const { texts } = useTexts();
        const isVisible = ref(false);

        setTimeout(() => {
            isVisible.value = true;
        }, 500);

        return {
            texts,
            isVisible,
        };
    },
});
